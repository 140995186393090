export const realtorData = {
  email: 'mygpropiedades@unne.cl',
  phone:'+56931280797'
};

export const footerData = {
  email: 'mygpropiedades@unne.cl',
  phone:'931280797',
  wsp:'931280797',
}
